import axios from "axios";
import { featureEnabled, getEnvKey } from "../environment";
import { ENV, buildNCDHeaders } from "../utils";
import { handleAxiosError } from "../axiosHelper";
import qs from "qs";
import ErrorLog, { LogLevel } from "../models/ErrorLog";
import Payment from "../models/Payment";

export const PAYMENT_VALIDATION_INSUFFICIENT_FUNDS = "202";
export const PAYMENT_VALIDATION_HARD_ERROR_CODES = ["202", "220", '223', "224", '300'];
export const PAYMENT_VALIDATION_SOFT_ERROR_CODES = ["203"] // and all the rest not listed above or below...
export const PAYMENT_VALIDATION_SUCCESS_RESPONSE_CODES = ['100'];
export const PAYMENT_VALIDATION_RETRY_LIMIT_EXCEEDED = "504";
export const PAYMENT_VALIDATION_VALID_ERROR_MESSAGE = 'duplicate transaction'
export interface IValidateTransactionResponse {
  success: boolean;
  showModal: boolean;
  responseCode: string | string[] | qs.ParsedQs | qs.ParsedQs[] | undefined 
  validationAttempt: number;
}

export function isValidResponse(response: string | undefined) {
  return response !==undefined && response.toLowerCase().includes(PAYMENT_VALIDATION_VALID_ERROR_MESSAGE)
}

export async function validateTransaction(
  agentId: number | undefined,
  appId: string,
  payment: Payment | undefined,
  setValidationAttempt: React.Dispatch<React.SetStateAction<number>>,
  validationAttempt: number
) {
  if (!featureEnabled(ENV.REACT_APP_VALIDATECCURL)) return;

  const url = getEnvKey(ENV.REACT_APP_VALIDATECCURL) || ''

  const defaultReturn: IValidateTransactionResponse = {
    success: true,
    showModal: false,
    responseCode: undefined,
    validationAttempt
  };

  // Skip validation for ACH
  if (payment?.paymentType !== 'CC') {
    return defaultReturn;
  }

  try {
    // Credit Card Validation
    // NOTE we (incorrectly?) refer to cvv as ccv throughout the app. So it is cvv here but other places ccv...
    const postData = {
      ccnumber: payment?.ccNumber,
      ccexp: `${payment?.ccExpMonth}${payment?.ccExpYear}`,
      cvv: payment?.ccSecurityCode
    };
    const response = await axios
      .post(url, postData, {
        headers: { 'Content-Type': 'application/json', ...buildNCDHeaders(agentId, appId)},
      })
      .catch((err) => {
        handleAxiosError(err)
        return err;
      });

    const responseParams = qs.parse(response.data);
    let responseCode = responseParams.response_code;
    let responseText = responseParams.responsetext as string;

    if (PAYMENT_VALIDATION_SUCCESS_RESPONSE_CODES.includes(responseCode as string) ||
    isValidResponse(responseText)) {
      return { ...defaultReturn, responseCode};
    } else if (PAYMENT_VALIDATION_HARD_ERROR_CODES.includes(responseCode as string)){
      setValidationAttempt(++defaultReturn.validationAttempt)
      if ( defaultReturn.validationAttempt >= 3 ) {
        responseCode = PAYMENT_VALIDATION_RETRY_LIMIT_EXCEEDED;
      }
      return { ...defaultReturn, success: false, showModal: true, responseCode }
    } else {
      return { ...defaultReturn, responseCode};
    }
  } catch (error) {
    const msg = 'Unexpected error during payment validation'
    ErrorLog.log(error, msg, LogLevel.error)
    return defaultReturn;
  }
}