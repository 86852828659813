import React, { FC } from "react";

const D2CAgreements: FC = () => {
    return (
        <>
            <div className="mb-2">
                The applicant for coverage agrees and acknowledges that the terms
                of coverage set forth below apply to the benefits applied for by
                the applicant to which the applicant also acknowledges and has
                accepted such terms of coverage.
            </div>
            <ol type="A" className=" list-inside list-[upper-alpha] p-[initial]">
                <li className="mb-2">
                    Authorization of Payment – The first initial charge will be
                    withdrawn immediately by NCD Agency, LLC. as well as all
                    subsequent withdrawals and the withdrawals will occur on the same
                    day each month (unless requested otherwise). If authorization is
                    to be revoked, the applicant recognizes that it can take up to 10
                    days to process the request. Accordingly, the applicant
                    acknowledges that any notice to withdraw payment authorization
                    given at the end of a billing cycle may not prevent the next
                    month’s charge being made against your account. If the debit to my
                    account is dishonored with or without cause, the applicant does
                    not hold NCD Agency, LLC. liable even if it results in the loss of
                    insurance coverage. If you wish to change the form of payment,
                    please contact NCD Agency, LLC. at 1-800-979-8266.
                </li>{" "}
                <li className="mb-2">
                    Effective Date of Coverage – The applicant’s insurance coverage
                    will not go into effect until the application is approved by the
                    insurance company and the initial payment is received by NCD
                    Agency, LLC. If payment is not received, the application will be
                    voided, and no coverage issued. Submission and acceptance of the
                    credit card or bank account information does not by itself
                    constitute acceptance of coverage.
                </li>
                <li className="mb-2">
                    Underwriter Information. Dental insurance coverage is underwritten
                    by Metropolitan Life Insurance Company, New York, NY 10166
                    (MetLife), vision insurance coverage is underwritten by Vision
                    Service Plan Insurance Company, Rancho Cordova, CA 95670 (VSP),
                    and offered through NCD Agency, LLC. There is no ownership
                    affiliation between MetLife, VSP and NCD Agency, LLC.
                </li>
                <li className="mb-2">
                    Data Privacy – We believe in protecting your information. The
                    online application system provided on this website is protected by
                    a strong SSL and encryption of sensitive information.
                </li>{" "}
                <li className="mb-2">
                    Automatic Renewal - Once enrolled, the plan will continue to
                    automatically renew, unless you, the applicant, send a
                    cancellation notice or the plan is otherwise terminated.
                </li>
                <li className="mb-2">
                    Right to Return Period – If you are not completely satisfied with
                    this coverage and have not filed a claim, you may return the
                    Certificate of Insurance within ten (10) days of the coverage
                    effective date and receive a refund of insurance premiums.
                </li>
                <li className="mb-2">
                    Certificate – Once insured, you will be receiving a Certificate of
                    Insurance. The Certificate is a description of coverage. The
                    master group insurance policy will govern on all matters of
                    benefits, limitations, exclusions and terms for keeping coverage
                    in force. You will be provided with instructions which will allow
                    you, once you are accepted for coverage, to download your
                    certificate electronically.
                </li>
                <li className="mb-2">
                    ID Cards – You will be provided with an ID Card once your coverage
                    becomes effective and becomes in-force. Your card will be sent to
                    you via USPS mail and/or by email.
                </li>
                <li className="mb-2">
                    Cancellation of Coverage – A five (5) day notice requirement in
                    advance of the scheduled billing date is required in order to
                    terminate your insurance coverage. Membership fees paid are
                    retained to the association and a separate notice requirement has
                    to be made directly to the association.
                </li>
                <li className="mb-2">
                    Dental insurance policies featuring the Preferred Dentist Program
                    are underwritten by Metropolitan Life Insurance Company, New York,
                    NY 10166. Like most insurance policies, insurance policies offered
                    by MetLife and its affiliates contain certain exclusions,
                    exceptions, reductions, limitations, waiting periods and terms for
                    keeping them in force. Please contact for costs and complete
                    details. Coverage may not be available in all states.
                </li>
            </ol>
            <div className="mb-2 text-2xl font-bold">
                Fraud Warning
            </div>{" "}
            <div className="mb-2">
                Alabama, Arkansas. District of Columbia, Louisiana,
                Massachusetts, New Mexico, Ohio, Rhode Island and West Virginia:
                Any person who knowingly presents a false or fraudulent claim
                for payment of a loss or benefit or knowingly presents false
                information in an application for insurance is guilty of a crime
                and may be subject to fines and confinement in prison.
            </div>
            <div className="mb-2">
                Colorado: It is unlawful to knowingly provide false, incomplete
                or misleading facts or information to an insurance company for
                the purpose of defrauding or attempting to defraud the company.
                Penalties may include imprisonment, fines, denial of insurance
                and civil damages. Any insurance company or agent of an
                insurance company who knowingly provides false, incomplete, or
                misleading facts or information to a policyholder or claimant
                for the purpose of defrauding or attempting to defraud the
                policyholder or claimant with regard to a settlement or award
                payable from insurance proceeds shall be reported lo the
                Colorado Division of Insurance within the Department of
                Regulatory Agencies to the extent required by applicable law.
            </div>
            <div className="mb-2">
                Florida: Any person who knowingly and with intent to injure,
                defraud or deceive any insurance company files a statement of
                claim or an application containing any false, incomplete or
                misleading information is guilty of a felony of the third
                degree.
            </div>
            <div className="mb-2">
                Kansas and Oregon: Any person who knowingly presents a
                materially false statement in an application for insurance may
                be guilty of a criminal offense and may be subject to penalties
                under state law.
            </div>
            <div className="mb-2">
                Kentucky: Any person who knowingly and with intent to defraud
                any insurance company or other person files an application for
                insurance containing any materially false information or
                conceals, for the purpose of misleading, information concerning
                any fact or material thereto commits a fraudulent insurance act,
                which is a crime.
            </div>
            <div className="mb-2">
                Maine, Tennessee and Washington: It is a crime to knowingly
                provide false, incomplete or misleading information to an
                insurance company for the purpose of defrauding the company.
                Penalties may include imprisonment, fines or a denial of
                insurance benefits.
            </div>
            <div className="mb-2">
                New Jersey: Any person who files an application containing any
                false or misleading information is subject to criminal and civil
                penalties.
            </div>
            <div className="mb-2">
                New York (only applies to Accident and Health Insurance): Any
                person who knowingly and with intent to defraud any insurance
                company or other person files an application for insurance or
                statement of claim containing any materially false information,
                or conceals for the purpose of misleading, information
                concerning any fact material thereto, commits a fraudulent
                insurance act, which is a crime, and shall also be subject to a
                civil penalty not to exceed five thousand dollars and stated
                value of the claim for each such violation.
            </div>
            <div className="mb-2">
                Oklahoma: WARNING: Any person who knowingly, and with intent to
                injure, defraud or deceive any insurer, make any claim for the
                proceeds of an insurance policy containing any false, incomplete
                or misleading information is guilty of a felony.
            </div>
            <div className="mb-2">
                Puerto Rico: Any person who knowingly and with the intention to
                defraud includes false information in an application for
                insurance or files, assists or abets in the filing of a
                fraudulent claim to obtain payment of a loss or other benefit,
                or files more than one claim for the same loss or damage,
                commits a felony and if found guilty shall be punished for each
                violation with a fine of no less than five thousand dollars
                ($5,000), not to exceed ten thousand dollars ($10,000); or
                imprisoned for a fixed term of three (3) years, or both. If
                aggravating circumstances exist, the fixed jail term may be
                increased to a minimum of five (5) years; and if mitigating
                circumstances are present, the jail term may be reduced to a
                minimum of two (2) years.
            </div>
            <div className="mb-2">
                Vermont: Any person who knowingly presents a false statement in
                an application for insurance may be guilty of a criminal offense
                and subject to penalties under state law.
            </div>
            <div className="mb-2">
                Virginia: Any person who, with the intent to defraud or knowing
                that he is facilitating a fraud against an insurer, submits an
                application or files a claim containing a false or deceptive
                statement may have violated the state law.
            </div>
            <div className="mb-2">
                Pennsylvania and all other states: Any person who knowingly and
                with intent to defraud any insurance company or other person
                files an application for insurance or statement of claim
                containing any materially false information, or conceals for the
                purpose of misleading, information concerning any fact material
                thereto commits a fraudulent insurance act, which is a crime and
                subjects such person to criminal and civil penalties.
            </div>
        </>
    );
};

export default D2CAgreements;