import { FC, useCallback } from "react";
import cx from "classnames";
import { D2CAgentId } from "../../utils";
import Button from "../../components/Button";

import AgreementsDoc from "./AgreementsDoc";

interface AgreementFormProps {
  agentId?: number;
  agreement: boolean;
  agreementTwo: boolean;
  handlePurchaseButton: () => void;
  didNotAgreeToTheTerms: boolean;
  setAgreement: React.Dispatch<React.SetStateAction<boolean>>;
  setAgreementTwo: React.Dispatch<React.SetStateAction<boolean>>;
  submitState: { loading: boolean };
}

const AgreementForm: FC<AgreementFormProps> = ({
  agentId,
  agreement,
  agreementTwo,
  handlePurchaseButton,
  didNotAgreeToTheTerms,
  setAgreement,
  setAgreementTwo,
  submitState,
}: AgreementFormProps) => {

  
  const handleOnAgreementChange = useCallback(() => {
    setAgreement(!agreement);
  }, [agreement]);

  const isD2C = Number(agentId) === D2CAgentId;

  return (
    <>
      <div className="mb-4 flex w-full items-center justify-center self-start rounded-3xl border-4 border-black bg-white p-3 text-center">
        <h1 className="flex flex-row items-center justify-center gap-4 text-[32px] font-bold text-navyBlue">
          Agreements
        </h1>
      </div>
      <div className="flex w-full flex-col gap-4 rounded-3xl border-4 border-black bg-white p-4 xl:p-8">
        <div className="min-h-[250px] max-w-[1117px] text-base font-normal text-black sm:max-h-[340px] sm:overflow-y-auto xl:h-full">
          <AgreementsDoc isD2C={isD2C} />
        </div>
        <div className={`bottom-[5.5rem] w-full pb-2`}>
          <div className="mb-4 h-[2px] w-full bg-navyBlue" />
          <div className="flex max-w-[70rem] flex-col items-center gap-4 xl:flex-row-reverse">
            <div className="flex flex-col gap-4">
              <div
                className="flex cursor-pointer flex-row gap-2"
                onClick={() => {
                  setAgreement(!agreement);
                }}
              >
                <input
                  type="radio"
                  className="min-h-[29px] min-w-[29px] border-[3px] border-solid border-lightBlue text-lightBlue accent-lightBlue checked:bg-lightBlue"
                  checked={agreement}
                  onChange={handleOnAgreementChange}
                />
                <div className="text-sm text-navyBlue">
                  By providing your email address, you agree and opt-in to receive membership
                  materials (including policy or certificate of issuance documents, if applicable),
                  and other correspondence electronically. If you prefer to have your membership
                  materials and documents mailed, please call Member Services at (800) 656-2204
                  Option #2. By checking the box, I acknowledge that I understand and agree
                  to the authorization.
                </div>
              </div>

              {isD2C && (
                <div
                  className="flex cursor-pointer flex-row gap-2"
                  onClick={() => {
                    setAgreementTwo(!agreementTwo);
                  }}
                >
                  <input
                    type="radio"
                    className="min-h-[29px] min-w-[29px] border-[3px] border-solid border-lightBlue text-lightBlue accent-lightBlue checked:bg-lightBlue"
                    checked={agreementTwo}
                    onClick={() => {
                      setAgreementTwo(!agreementTwo);
                    }}
                  />
                  <div className="text-sm text-navyBlue">
                    Enrollment Application - The Dental Insurance Plan of Benefits is one
                    of the many benefits being offered by the National Wellness and Fitness
                    Association. In order to apply for the insurance coverage, you must
                    be a member. By completing this application for coverage and the
                    authorization for payment of the charges, the applicant acknowledges
                    his/her membership in the Association and monthly membership fee.
                  </div>
                </div>
              )}
            </div>
            <div
              className={cx(
                "flex w-full flex-col items-center",
                "md:flex-row-reverse md:justify-between",
                "xl:w-full xl:flex-col"
              )}
            >
              <Button
                text={"PURCHASE"}
                className="purchasePlan max-w-[320px] xl:mt-5"
                action={handlePurchaseButton}
                mainPath
                disabled={submitState.loading}
                loadingIcon={submitState.loading}
              />
              <div className="ml-2 mt-4 md:mt-0 xl:mt-4">
                {didNotAgreeToTheTerms && (
                  <p className="pr-2 text-xs font-normal text-red">
                    You must agree to the terms and conditions!
                  </p>
                )}
              </div>
              {isD2C && (
                <a
                  className="mx-2 mt-2 font-bold text-blue md:mt-0 xl:mt-4"
                  href="https://metlife-files.s3.us-west-2.amazonaws.com/CA+LAP+Notice+(Dental_Vision).pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  View CA LAP Notice
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgreementForm;