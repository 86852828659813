import React, { FC } from "react";
import AgentAssistedAgreements from "./AgentAssistedAgreements";
import D2CAgreements from "./D2CAgreements";

interface AgreementsDocProps {
    isD2C: boolean;
}

const AgreementsDoc: FC<AgreementsDocProps> = ({ isD2C }) => {
    return (
        <>
            <div className="mb-2 text-2xl font-bold">AUTHORIZATION OF COVERAGE AND PAYMENT</div>
            <div className="mb-2">
                I hereby apply for the coverage(s) denoted above and do hereby
                declare that all above answers are true and complete. With respect
                to payment for the selected coverage(s), I authorize NCD Agency, LLC.
                to automatically charge the recurring monthly premiums for the Plan
                selected by me, along with the association membership fees, against
                my credit card or bank account as designated above. This payment option
                will remain in effect for the duration of my coverage and the authorization
                will remain in effect until revoked by me in writing by notifying NCD Agency, LLC.
                As part of my acceptance of coverage based upon the information provided,
                I acknowledge the terms of coverage set forth in the NCD Membership Agreement
                set forth in the attached document.
            </div>{" "}
            <div className="mb-2">
                {" "}
                By my completion of the application for coverage and the actual
                payment by me of the required charges for the Dental and/or Vision
                Insurance through my credit card or bank account (as designated), I
                agree to the terms of the enrollment, the Authorization, and the NCD
                Agreement. The Dental Insurance coverage will be effective based on
                my acceptance of the terms of Authorization as acknowledged by my
                payment of the required charges as required and acceptance by the
                insurance carrier.
            </div>
            <div className="mb-2 text-2xl font-bold">
                NCD MEMBER AGREEMENT
            </div>{" "}
            {isD2C ? <D2CAgreements /> : <AgentAssistedAgreements />}
            <div className="mb-2 text-2xl font-bold">
                NEW MEXICO DISCLOSURE
            </div>{" "}
            <div className="mb-2">
                This type of plan is NOT considered “minimum essential coverage”
                under the Affordable Care Act and therefore does NOT satisfy the
                individual mandate that you have health insurance coverage. If
                you do not have other health insurance coverage, you may be
                subject to a federal tax penalty.
            </div>
            <div className="mb-2 text-2xl font-bold">
                METLIFE NWFA NOTICE
            </div>{" "}
            <div className="mb-2">
                Metropolitan Life Insurance Company is unaffiliated with the
                National Wellness & Fitness Association. Metropolitan Life
                Insurance Company only provides benefits covered under its
                insurance policies and is not responsible for other benefits
                and/or discounts associated with membership in the NWFA.
            </div>
        </>
    );
};

export default AgreementsDoc;