import React, { FC } from "react";

const AgentAssistedAgreements: FC = () => {
    return (
        <>
            <div className="mb-2">
                The applicant for coverage agrees and acknowledges that the terms
                of coverage set forth below apply to the benefits applied for by
                the applicant to which the applicant also acknowledges has been
                read to him/her and has accepted such terms of coverage.
            </div>
            <ol type="A" className=" list-inside list-[upper-alpha] p-[initial]">
                <li className="mb-2">
                    Authorization of Payment – The first initial charge will be
                    withdrawn immediately by NCD Agency, LLC. as well as all
                    subsequent withdrawals and the withdrawals will occur on the same
                    day each month (unless requested otherwise). If authorization is
                    to be revoked, the applicant recognizes that it can take up to 10
                    days to process the request. Accordingly, the applicant
                    acknowledges that any notice to withdraw payment authorization
                    given at the end of a billing cycle may not prevent the next
                    month’s charge being made against your account. If the debit to my
                    account is dishonored with or without cause, the applicant does
                    not hold NCD Agency, LLC. liable even if it results in the loss of
                    insurance coverage. If you wish to change the form of payment,
                    please contact NCD Agency, LLC. at 1-800-979-8266.
                </li>{" "}
                <li className="mb-2">
                    Effective Date of Coverage – The applicant’s insurance coverage
                    will not go into effect until the application is approved by the
                    insurance company and the initial payment is received by NCD
                    Agency, LLC. If payment is not received, the application will be
                    voided, and no coverage issued. Submission and acceptance of the
                    credit card or bank account information does not by itself
                    constitute acceptance of coverage.
                </li>
                <li className="mb-2">
                    Underwriter Information. Dental insurance coverage is underwritten
                    by Metropolitan Life Insurance Company, New York, NY 10166
                    (MetLife), vision insurance coverage is underwritten by Vision
                    Service Plan Insurance Company, Rancho Cordova, CA 95670 (VSP),
                    and offered through NCD Agency, LLC. There is no ownership
                    affiliation between MetLife, VSP and NCD Agency, LLC.
                </li>
                <li className="mb-2">
                    Enrollment Application - The Dental Insurance Plan of Benefits
                    is one of the many benefits being offered by the National
                    Wellness and Fitness Association. In order to apply for the
                    insurance coverage, you must be a member. By completing this
                    application for coverage and the authorization for payment of
                    the charges, the applicant acknowledges his/her membership in
                    the Association and monthly membership fee.
                </li>
                <li className="mb-2">
                    Data Privacy – We believe in protecting your information. The
                    online application system provided on this website is protected by
                    a strong SSL and encryption of sensitive information.
                </li>{" "}
                <li className="mb-2">
                    Automatic Renewal - Once enrolled, the plan will continue to
                    automatically renew, unless you, the applicant, send a
                    cancellation notice or the plan is otherwise terminated.
                </li>
                <li className="mb-2">
                    Right to Return Period – If you are not completely satisfied with
                    this coverage and have not filed a claim, you may return the
                    Certificate of Insurance within ten (10) days of the coverage
                    effective date and receive a refund of insurance premiums.
                </li>
                <li className="mb-2">
                    Certificate – Once insured, you will be receiving a Certificate of
                    Insurance. The Certificate is a description of coverage. The
                    master group insurance policy will govern on all matters of
                    benefits, limitations, exclusions and terms for keeping coverage
                    in force. You will be provided with instructions which will allow
                    you, once you are accepted for coverage, to download your
                    certificate electronically.
                </li>
                <li className="mb-2">
                    ID Cards – You will be provided with an ID Card once your coverage
                    becomes effective and becomes in-force. Your card will be sent to
                    you via USPS mail and/or by email.
                </li>
                <li className="mb-2">
                    Cancellation of Coverage – A five (5) day notice requirement in
                    advance of the scheduled billing date is required in order to
                    terminate your insurance coverage. Membership fees paid are
                    retained to the association and a separate notice requirement has
                    to be made directly to the association.
                </li>
                <li className="mb-2">
                    Dental insurance policies featuring the Preferred Dentist Program
                    are underwritten by Metropolitan Life Insurance Company, New York,
                    NY 10166. Like most insurance policies, insurance policies offered
                    by MetLife and its affiliates contain certain exclusions,
                    exceptions, reductions, limitations, waiting periods and terms for
                    keeping them in force. Please contact for costs and complete
                    details. Coverage may not be available in all states.
                </li>
            </ol>
        </>
    );
};

export default AgentAssistedAgreements;